<template>
  <div class="no_data">
    <div class="no_data-center">
      <img :src="noDataImg" alt="暂无数据" />
      <p>暂无数据……</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  data() {
    return {
      noDataImg: require('@assets/img/new_nodata.png')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.no_data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .no_data-center {
    img {
      display: inline-block;
      width: 152px;
      text-align: center;
    }
    p {
      margin-top: 36px;
      color: #999;
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
